<!--
 * @Author: wuruoyu
 * @Description: layout
 * @Date: 2020-12-22 14:00:37
 * @LastEditors: wuruoyu
 * @LastEditTime: 2020-12-23 16:41:42
 * @FilePath: \huayun_platform\src\layout\index.vue
-->
<template>
  <div class="layout">
    <div class="slider-box">
      <Siderbar></Siderbar>
    </div>
    <div class="content-inner">
      <div class="header-box">
        <Header></Header>
      </div>
      <div class="mainbox">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./Header"
import Siderbar from "./Siderbar"
export default {
  components: {
    Header,
    Siderbar
  }
}
</script>

<style lang="scss" scoped>
  .layout{
    height: 100%;
    width: 100%;
    background:$project-bg;
    .slider-box{
      width: 260px;
      height: 100%;
      float: left;
    }
    .content-inner{
      width: calc(100% - 260px);
      height: 100%;
      float: left;
      display: flex;
      flex-direction: column;
      .header-box{
        height: 56px;
      }
      .mainbox{
        flex: 1;
        width: 100%;
        overflow: auto;
        &::-webkit-scrollbar {
          width: 0;
        }
      }
    }
  }
</style>
