<!--
 * @Author: wuruoyu
 * @Description: header
 * @Date: 2020-12-22 14:02:06
 * @LastEditors: wuruoyu
 * @LastEditTime: 2020-12-23 09:30:34
 * @FilePath: \huayun_platform\src\layout\Header.vue
-->
<template>
  <header class="header">

  </header>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  components: {

  }
}
</script>

<style lang="scss" scoped>
  .header{
    height: 56px;
    background:$block-bg;
  }
</style>
