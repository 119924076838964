<!--
 * @Author: wuruoyu
 * @Description: 导航
 * @Date: 2020-12-22 14:05:58
 * @LastEditors: year
 * @LastEditTime: 2021-01-15 16:54:09
 * @FilePath: \huayun_platform\src\layout\Siderbar.vue
-->
<template>
  <div class="sidebar">
    <TopTitleCard></TopTitleCard>
    <div class="menubox">
      <!-- <div class="first-menu" @mouseenter="menuOpen" @mouseleave="menuClose">
        <el-menu
          default-active=""
          class="collapse-menu"
          :show-timeout="200"
          :hide-timeout="200"
          vertical="vertical"
          background-color="#1A2137"
          text-color="#878A96"
          active-text-color="#ffffff"
          :collapse="isCollapse"
        >
          <el-menu-item index="0">
            <div class="toggle-menu-item">
              <i class="el-icon-s-operation"></i>
              <span slot="title">服务列表</span>
            </div>
          </el-menu-item>
          <el-menu-item
            v-for="item in routerList"
            :index="item.name"
            :key="item.name"
            @click="menuClick(item)"
          >
            <i :class="item.meta.icon"></i>
            <span slot="title">{{ item.meta.title }}</span>
          </el-menu-item>
        </el-menu>
      </div> -->
      <SubMenuList :list="subList"></SubMenuList>
    </div>
  </div>
</template>

<script>
import TopTitleCard from "./TopTitleCard";
import SubMenuList from "./component/SubMenuList";
import { menuRoute } from "@/router/index";
export default {
  data() {
    return {
      isCollapse: true,
      // [
      //   {
      //     name:'Personnal',
      //     meta:{
      //       title:'人员管理',
      //       icon:'el-icon-s-custom',
      //       hash:'/platformmanage/personnal'
      //     },
      //   },
      //   {
      //     name:'Project',
      //     meta:{
      //       title:'项目组织',
      //       icon:'el-icon-bank-card',
      //       hash:'/platformmanage/project'
      //     },
      //   },
      //   {
      //     name:'Limits',
      //     meta:{
      //       title:'权限管理',
      //       icon:'el-icon-lock',
      //       hash:'/platformmanage/limits'
      //     }
      //   }
      // ]
      subList: [
        {
          name: "Personnal",
          meta: {
            title: "人员管理",
            icon: "el-icon-s-custom",
            hash: "/platformmanage/personnal",
          },
        },
        {
          name: "Project",
          meta: {
            title: "项目组织",
            icon: "el-icon-bank-card",
            hash: "/platformmanage/project",
          },
        },
        {
          name: "Limits",
          meta: {
            title: "权限管理",
            icon: "el-icon-lock",
            hash: "/platformmanage/limits",
          },
        },
      ],
    };
  },
  computed: {
    routerList() {
      return menuRoute.children;
    },
  },
  components: {
    TopTitleCard,
    SubMenuList,
  },
  methods: {
    menuOpen() {
      this.isCollapse = false;
    },
    menuClose() {
      this.isCollapse = true;
    },
    menuClick(data) {
      this.subList = data.children || [];
      this.isCollapse = true;
    },
  },
  mounted() {
    console.log(menuRoute);
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  width: 260px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: $side-bg;
  .menubox {
    flex: 1;
    padding-left: 50px;
    overflow: auto;
    position: relative;
    &::-webkit-scrollbar {
      width: 0 !important;
    }
  }
  .collapse-menu {
    width: 260px;
    border: none;
  }
  .first-menu {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    & /deep/ .el-menu-item {
      padding: 0 10px !important;
      height: 60px;
      font-size: 18px;
      font-weight: 400;
      i {
        font-size: 24px;
      }
    }
    & /deep/ .el-menu--collapse {
      width: 50px;
      border: none;
    }
    & /deep/ .el-tooltip {
      padding: 0 10px !important;
      width: 50px;
      display: flex;
      justify-content: center;
    }
  }
  .toggle-menu-item {
    height: 59px;
    width: 100%;
    border-bottom: 1px solid #53545a;
  }
}
</style>

<style lang="scss">
.sidebar {
  .el-menu-item {
    height: 58px !important;
  }
}
</style>
